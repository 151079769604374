(function($){
	const siteName = 'kuroda-group';
	const a = 'is-active';
	const v = 'is-visible';
	const f = 'is-fixed';
	const slideSpeed = '400';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision() {
			var regExp = new RegExp(`(test\\.${siteName}\\.connecty\\.com|copre\\.jp|localhost|192\\.168\\.0\\.|192\\.168\\.11\\.|192\\.168\\.1\\.)`, 'g');
			return regExp.test(location.hostname);
		},
		localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll(`[class*="${key}"]`);
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done((...args) => {
					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		getQuery(){
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for(var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		headerMenu() {
			const $hamburger = $('.header-hamburger');
			const $nav = $('.header-nav');
			const $overlay = $('.header-overlay');
			let scrollTopNow;

			const fixedOnContainer = () => {
				scrollTopNow = $(window).scrollTop();
				$('.container').css({
					position: 'fixed',
					width: '100%',
					top: -1 * scrollTopNow,
					left: 0
				});
				$('body').addClass(f);
			};
			const fixedOffContainer = () => {
				$('.container').css({
					position: 'static'
				});
				$('body').removeClass(f);
				$(window).scrollTop(scrollTopNow);
			};
			const spHeaderHamburger = () => {
				$hamburger.on('click', (e) => {
					$(e.currentTarget).toggleClass(a);
					if ($nav.hasClass(a)) {
						$nav.removeClass(v);
						$overlay.removeClass(v);
						setTimeout(() => {
							$nav.removeClass(a);
							$overlay.removeClass(a);
						}, 400);
						fixedOffContainer();
					} else {
						fixedOnContainer();
						$overlay.addClass(a);
						$nav.addClass(a);
						setTimeout(() => {
							$overlay.addClass(v);
							$nav.addClass(v);
						});
					}
				});
			};

			// innerMenu開閉
			document.querySelectorAll('.header-nav-main summary').forEach(ele => {
				if (FUNCTIONS.va.window.width > FUNCTIONS.va.device.sp) {
					ele.addEventListener('mouseenter', () => {
						if (!ele.closest('details').open) {
							document.querySelectorAll('.header-nav-main details').forEach(ele2 => {
								if (ele !== ele2 && ele2.closest('details').open) {
									let openMenu = ele2.querySelector('.__innerMenu');
									const close = openMenu.animate(
										{ opacity: [1, 0] },
										{ duration: 300, easing: 'ease' }
									);
									close.onfinish = () => { ele2.removeAttribute('open'); }
								}
							});
							if (ele.nextElementSibling) {
								ele.closest('details').setAttribute('open', 'true');
								ele.nextElementSibling.animate(
									{ opacity: [0, 1] },
									{ duration: 300, easing: 'ease' }
								);
							}
						}
					});
				} else {
					if (ele.nextElementSibling) {
						ele.querySelector('a').addEventListener('click', (e) => { e.preventDefault(); });
						ele.addEventListener('click', () => {
							if (!ele.closest('details').open) {
								document.querySelectorAll('.header-nav-main details').forEach(ele2 => {
									if (ele !== ele2 && ele2.closest('details').open) {
										ele2.removeAttribute('open');
									}
								});
								ele.closest('details').setAttribute('open', 'true');
								ele.nextElementSibling.animate(
									{ opacity: [0, 1] },
									{ duration: 300, easing: 'ease' }
								);
							} else {
								ele.closest('details').removeAttribute('open');
							}
						});
					}
				}
			});

			// アンカーリンク処理
			document.querySelectorAll('.header-nav-main .__innerMenu a').forEach(ele => {
				if (ele.attributes.href.value.indexOf('#') !== -1 && ele.attributes.href.value.split('#')[0] === FUNCTIONS.va.pathname) {
					ele.setAttribute('href', '#' + ele.attributes.href.value.split('#')[1]);
					ele.setAttribute('rel', 'scroll');
				}
			});

			if (FUNCTIONS.va.window.width > FUNCTIONS.va.device.sp) {
				document.querySelector('.header').addEventListener('mouseleave', () => {
					if (document.querySelector('.header-nav-main details[open]')) {
						let openMenu = document.querySelector('.header-nav-main details[open] .__innerMenu');
						const close = openMenu.animate(
							{ opacity: [1, 0] },
							{ duration: 300, easing: 'ease' }
						);
						close.onfinish = () => {
							openMenu.closest('details').removeAttribute('open');
						}
					}
				});
			} else {
				spHeaderHamburger();

				// [SP] ヘッダーのページ内アンカークリック時はハンバーガーメニューを閉じる
				document.querySelectorAll('.header-nav-main .__innerMenu a[rel=scroll]').forEach(ele => {
					ele.addEventListener('click', () => {
						$hamburger.removeClass(a);
						$nav.removeClass(v);
						$overlay.removeClass(v);
						setTimeout(() => {
							$nav.removeClass(a);
							$overlay.removeClass(a);
						}, 400);
						fixedOffContainer();
					});
				});
			}

		},
		nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll() {
			$('a[rel="scroll"]').on('click', (event) => {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
						pos -= 60;
					} else {
						pos -= 100;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash() {
			const hash = location.hash;
			if (hash) {
				$('html, body').css('display', 'none');

				setTimeout(() => {
					$('html, body').css('display', 'block');

					var _hash = '#' + hash.split('#')[1];
					var pos = $(_hash).offset().top;

					if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
						pos -= 60;
					} else {
						pos -= 100;
					}
					$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
				}, 100);
			}
		},
		pageTop() {
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				// var $footer = $('.footer');
				// var footerPos = $footer.offset().top + $footer.height();
				var thisScroll = $this.scrollTop();
				// var pagetopPos = $this.height() + $footer.height() + thisScroll;

				if (thisScroll > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}
			});
		},
		loadDelayScript() {
			let _this = this;
			_this.headerMenu();
			_this.nextToggle();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
		}
	};

	$(() => FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript());
})(window.jQuery);
